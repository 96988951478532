import { defineStore } from 'pinia';

export const useCampaignStore = defineStore('campaign', {
  state () {
    return {

        /*
        * NOTES:
        *
        * this could be simplified to use a single script (code) since they're all the same
        * and just have the valid paths in a single array BUT leaving this way until
        * Brett is back to get more direction
        *
        * Also need to address regions/territories
        *
        * */

      pre: '<!-- This site is converting visitors into subscribers and customers with OptinMonster - https://optinmonster.com -->',
      post: '<!-- / OptinMonster -->',
      campaigns: [
          {
              name: 'ARC Timer (FR)',
              paths: ['/products/big-gun/150-series'],
              code: "(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,131721,144210);"
          },
         {
              name: 'Feedlot',
              paths: ['/solutions/high-volume/feedlot-dust-suppression-cooling'],
              code: "(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,131721,144210);"
          },
          {
              name: 'Mine Expo',
              paths: [
                  '/solutions/non-agricultural-applications/dust-suppression',
                  '/solutions/non-agricultural-applications/mining'
              ],
              code: "(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,131721,144210);"
          },
          {
              name: 'Nelson Austrailia',
              paths: ['/'],
              code: "(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,131721,144210);"
          },
          {
              name: 'Newsletter',
              paths: ['/'],
              code: "(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,131721,144210);"
          },
          {
              name: 'R2000FX',
              paths: ['/products/rotator-sprinklers/R2000fx-rotator'],
              code: "(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,131721,144210);"
          },
          {
              name: 'S7',
              paths: ['/products/spinner-and-spray-sprinklers/s7-spinner'],
              code: "(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,131721,144210);"
          },
          {
              name: 'Windfighter',
              paths: ['/products/rotator-sprinklers/r2000wf-rotator'],
              code: "(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,131721,144210);"
          },
          {
              name: 'Windfighter (FL ONLY)',
              paths: ['/products/rotator-sprinklers/r2000wf-rotator'],
              code: "(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,131721,144210);"
          },

      ]
    };
  },
  actions: {
      getCampaign () {
          const router = useRouter();
          let routerPath = router.currentRoute.value.fullPath;
          let campaignData = null;
          this.campaigns.forEach( (campaign) => {
              campaign.paths.forEach( (path) => {
                  if (path  === routerPath) {
                      campaignData = campaign;
                  }
              });
          });
          return campaignData;
      }
  },
});
