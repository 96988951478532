<template>
   <!-- <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir"> -->
  <Bar :isTop="true" />
  <Nav />
  <main>
    <slot />
    <Nuxt />
  </main>
  <Footer />
  <Bar :isTop="false" />
<!-- </Html> -->
</template>
<script setup>
/* const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
}) */
</script>
<script>
import Nav from '~/components/main/Nav.vue';
import Bar from '~/components/main/Bar.vue';
import Footer from '~/components/main/Footer.vue';
import { useCampaignStore } from '~/store/CampaignStore';
import { useScriptStore } from '~/store/ScriptStore';
import VideoOverlay from "~/components/main/VideoOverlay.vue";

export default {

  data() {
    return {
      campaign: null,
      campaignScript: null
    };
  },

  components: {
    VideoOverlay,
    Nav,
    Bar,
    Footer
  },

  mounted() {
    this.loadCampaign();
    this.loadScripts();
  },
  beforeUpdate() {

    // spa reference
    // https://optinmonster.com/docs/using-the-optinmonster-api-with-single-page-applications/

    let arr = Array.from(document.body.getElementsByClassName('Campaign--css'));

    if (arr.length > 0) {
      arr[0].remove();
    }

  },

  updated() {

    this.loadCampaign();
  },

  methods: {
    loadCampaign() {

      this.campaign = useCampaignStore().getCampaign();
      if (this.campaign === null) {
        return;
      }

      if (window.om144210_131721) {
        window.om144210_131721.reset();
      }

      this.campaignScript = document.createElement('script');
      this.campaignScript.innerHTML = this.campaign.code;
      document.body.appendChild(this.campaignScript);

    },

    loadScripts() {

      this.script = useScriptStore().getScript('hubspot cookie optin');
      //  console.log(this.script);

      let scriptElement = document.createElement('script');

      for (const [key, value] of Object.entries(this.script.script)) {
        scriptElement.setAttribute(key, value);
      }

      document.head.appendChild(scriptElement);


    }
  }
};

</script>

<style scoped></style>